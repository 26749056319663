<template>
    <div class="">
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
        <div class="card-table-body ifon-add-title-block">
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="18">
                <div class="d-flex">
                    <div class="caz-blocks-sarcho-title" style="width: 80%;">
                        <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2">{{$t('message.deals')}}</div>
                        <div class="filter_filial w-100">
                            <select-from-filial
                                class="ml-2"
                                :size="'mini'"
                                :select_only="false"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.from_filial.title"
                                v-model="filterForm.from"
                            >
                            </select-from-filial>
                            <select-to-filial
                                class="ml-2"
                                :size="'mini'"
                                :show_all="1"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="columns.to_filial.title"
                                v-model="filterForm.to"
                            >
                            </select-to-filial>
                            <select-tariff
                                class="ml-2" 
                                :size="'mini'"
                                :placeholder="$t('message.directionTariff')"
                                :from_filial_id="filterForm.from"
                                :to_filial_id="filterForm.to"
                                v-model="filterForm.direction_tariff_id"
                            >
                            </select-tariff>
                            <el-select v-model="filterForm.cargo_type" :placeholder="$t('message.cargo_type')" class="ml-2" size="mini" clearable>
                                <el-option
                                    v-for="item in cargoTypes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                            <el-date-picker
                                class="ml-2"
                                :size="'mini'"
                                v-model="filterForm.from_date"
                                type="date"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="$t('message.from')"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            ></el-date-picker>
                            <el-date-picker
                                class="ml-2"
                                :size="'mini'"
                                v-model="filterForm.to_date"
                                type="date"
                                :class="mode ? 'filter__day' : 'filter__night'"
                                :placeholder="$t('message.to')"
                                format="yyyy-MM-dd"
                                value-format="yyyy-MM-dd"
                            ></el-date-picker>
                        </div>
                    </div>
                    <div class="text-right" style="width: 20%;">
                        <el-button type="danger" size="mini" class="ml-2" @click="deleteAll()">{{ $t('message.delete') }}</el-button>
                    </div>
                </div>
            </el-col>
          </el-row>
        </div>
  
        <!-- end ifon-add-title-block -->
          <div class="card-table-header table-crm-smart custom__scroll" v-loading="loadingData" element-loading-spinner="el-icon-loading">
            <el-table
                ref="multipleTable"
                :data="listData"
                border stripe
                style="width: 100%"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column :label="columns.id.title" width="120">
                    <template slot-scope="scope">
                        <i v-if="scope.row.cargo_type === 'parcel'" class="el-icon-message" style="color: #0017ff;"></i> 
                        <i v-else class="el-icon-box" style="color: #7f6918;"></i> 
                        {{ scope.row.id }}    
                    </template>
                </el-table-column>
                <el-table-column :label="columns.user.title">
                    <template slot-scope="scope">{{ scope.row.user ? scope.row.user.name : '' }}</template>
                </el-table-column>
                <el-table-column :label="columns.tracking_code.title">
                    <template slot-scope="scope">{{ scope.row.tracking_code }}</template>
                </el-table-column>
                <el-table-column :label="columns.reciever.title">
                    <template slot-scope="scope">
                        <span v-if="scope.row.reciever">
                            {{ scope.row.reciever ? (scope.row.reciever.custom_id ? ('ID: ' + scope.row.reciever.custom_id) : '') : '' }} <br>
                            {{ scope.row.reciever ? scope.row.reciever.name : '' }}<br>
                            {{ scope.row.reciever ? scope.row.to_client_phone : '' }}
                        </span>    
                    </template>
                </el-table-column>
                <el-table-column :label="columns.direction_tariff.title">
                    <template slot-scope="scope">{{ scope.row.directionTariff ? scope.row.directionTariff.name : '' }}</template>
                </el-table-column>

                <el-table-column :label="columns.created_at.title">
                    <template slot-scope="scope">{{ scope.row.created_at }}</template>
                </el-table-column>
            </el-table>


              <el-dialog class="dialog__modal" :title="$t('message.products')" :visible.sync="dialogItemsVisible" width="60%" @closed="empty()" >
                  <div v-loading="loadingProducts">
                    <el-table :data="dealProducts" show-summary :summary-method="getSummaries" border>
                      <el-table-column
                          type="index"
                          width="50">
                      </el-table-column>
                      <el-table-column prop="name" :label="$t('message.name')">
                          <template slot-scope="scope">
                            {{ scope.row.product ? scope.row.product.name : '' }}
                          </template>
                      </el-table-column>
  
                      <el-table-column prop="quantity" :label="$t('message.quantity')"></el-table-column>
                      
                      <el-table-column prop="incoming_quantity" :label="$t('message.incoming_quantity')">
                          <template slot-scope="scope">
                            {{ scope.row.incoming_quantity }}
                          </template>
                      </el-table-column>
  
                      <el-table-column prop="remainder" :label="$t('message.remainder')">
                          <template slot-scope="scope">
                            {{ scope.row.quantity - scope.row.incoming_quantity }}
                          </template>
                      </el-table-column>
  
                     <el-table-column prop="weight" :label="$t('message.weight_in_kg')"></el-table-column>
                     <el-table-column prop="weight_in_pounds" :label="$t('message.weight_in_pounds')"></el-table-column>
                      <el-table-column prop="price" :label="$t('message.price')">
                          <template slot-scope="scope">
                            {{ scope.row.price | formatNumber(1) }}
                          </template>
                      </el-table-column>
                      <el-table-column prop="currency" :label="$t('message.currency')">
                          <template slot-scope="scope">
                            {{ (scope.row.currency ? scope.row.currency.code : '')}}
                          </template>
                      </el-table-column>
                      <el-table-column :label="$t('message.total')">
                          <template slot-scope="scope">
                            {{ (parseFloat(scope.row.currency_rate) * parseFloat(scope.row.weight) * parseFloat(scope.row.price)).toFixed(2) }} 
                            {{ system_currency }}
                          </template>
                      </el-table-column>
                    </el-table>
                  </div>
              </el-dialog>
              <div class="my___pagination">
                <crm-pagination
                    :class="mode ? 'pagination__day' : 'pagination__night'"
                    @c-change="updatePagination"
                    :pagination="pagination"
                ></crm-pagination>
              </div>
          </div>
  
          <el-drawer
              :visible.sync="drawerDealCreate"
              :with-header="false"
              size="95%"
              class="body_scroll"
              ref="drawerDealCreate"
              @opened="drawerOpened('drawerDealCreateChild')"
              @closed="drawerClosed('drawerDealCreateChild')"
          >
              <AppModal
                  ref="drawerDealCreateChild"
                  :selectedDeal="selectedDeal"
                  :dealCreate="dealCreate"
                  :dealEdit="dealEdit"
                  :cargo_type="cargo_type"
                  drawer="drawerDealCreate"
              />
          </el-drawer>

          <el-dialog
                class="KP_modal"
                :title="$t('message.delete')"
                :visible.sync="drawerDeleteDeal"
                :append-to-body="true"
                width="30%"
                center
                :class="mode ? 'l-modal-style internal' : 'd-modal-style internal'"
                ref="drawerDeleteDeal"
                @opened="drawerOpened('deleteDealComponent')"
                @closed="drawerClosed('deleteDealComponent')"
            >
            <div>
                <el-form ref="form" :model="form" :rules="rules">
                    <el-form-item prop="reason_id" :label="$t('message.reason')">
                        <select-reason
                            :size="'medium'"
                            :placeholder="$t('message.reason')"
                            :id="form.reason_id"
                            v-model="form.reason_id"
                        >
                        </select-reason>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="drawerDeleteDeal = false">{{ $t("message.cancel") }}</el-button>
                    <el-button @click="confirmDeleting()" type="primary" v-loading="loadingButton">{{ $t("message.confirm") }}</el-button>
                </span> 
            </div>
          </el-dialog>
      </div>
    </div>
  </template>
  
  <script>
  // @ is an alias to /src
  import Pagination from "@/components/el-pagination";
  import AppModal from "./components/add-modal";
  import selectClient from "@/components/inventory/select-client";
  import selectTariff from "@/components/selects/select-direction-tariff";
  import selectFromFilial from "@/components/selects/select-from-filial";
  import selectToFilial from "@/components/selects/select-to-filial";
  import selectReason from "@/components/inventory/select-reason";
  
  import list from "@/utils/mixins/list";
  import {mapGetters, mapActions} from 'vuex'
  import { formatMoney, formatNumber } from "@/filters/index";
  import SelectUsers from '../../components/inventory/select-users';
  
  export default {
      name: "deal",
      mixins: [list],
      components: {
          Pagination,
          AppModal,
          selectClient,
          selectTariff,
          selectFromFilial,
          selectToFilial,
          SelectUsers,
          selectReason
      },
  
      data() {
          return {
              dealEdit: false,
              drawerDeleteDeal: false,
              dialogItemsVisible: false,
              loadingData: false,
              dealCreate: true,
              form: {
                reason_id: null,
                deals: []
              },
              listData: [],
              system_currency: process.env.VUE_APP_CURRENCY,
              selectedDeal: null,
              cargo_type: '',
              drawerDealCreate: false,
              loadingProducts: false,
              cargoTypes: [
                  {
                      value: 'parcel',
                      label: this.$t('message.parcels')
                  }, 
                  {
                      value: 'commertial_cargo',
                      label: this.$t('message.commertial_cargo')
                  },
              ],
              rules: {
                reason_id: [
                    { required: true, message: this.$t('message.please_select_input', {input: this.$t('message.reason')}), trigger: 'change' },
                ],
              },
              loadingButton: false,
          };
      },
      computed: {
          ...mapGetters({
              permissions: "auth/permissions",
              dealProducts: "dealProducts/dealProducts",
              list: "deals/list",
              columns: "deals/columns",
              pagination: "deals/pagination",
              filter: "deals/filter",
              sort: "deals/sort",
              authUser: "auth/user", 
              total: "deals/total",
              total_paid: "deals/total_paid",
              mode: "MODE"
          }),
          actions: function () {
              return ["edit", "delete"];
          },
          watchMainFilials(){
              return this.filterForm.from + this.filterForm.to;
          }
      },
      watch:{
          filterForm: {
              handler: async function(newVal, oldVal) {
                await this.updatePagination({ key: "page", value: 1 });
                await this.updateFilter({...newVal, placement: 'new_deals'});
                this.debouncedFetchData();
              },
              deep: true,
              immediate: true
          },
          watchMainFilials: {
              handler: async function(newVal, oldVal) {
                if (newVal) {
                  let query = {from: this.filterForm.from, to: this.filterForm.to};
                  if(this.$refs['filter_table']){
                      this.$refs['filter_table'].updateFilterOptions({...query, show_totals: true});
                  }
                }
                else{
                  if(this.$refs['filter_table']){
                      this.$refs['filter_table'].updateFilterOptions({show_totals: true});
                  }
                }
              },
              deep: true,
              immediate: true
          },
  
      },
      methods: {
          ...mapActions({
              updateList: "deals/index",
              setPagination: "deals/setPagination",
              updateSort: "deals/updateSort",
              updateFilter: "deals/updateFilter",
              updateColumn: "deals/updateColumn",
              toggleColumns: "deals/toggleColumns",
              updatePagination: "deals/updatePagination",
              show: "deals/show",
              empty: "dealProducts/empty",
              delete: "deals/destroy",
              updateDealProducts: "dealProducts/show",
              refreshData: "deals/refreshData",
              batchDeleteDeal: "deals/batchDeleteDeal",
          }),

          fetchData() {
            const query = {...this.filter, ...this.pagination, ...this.sort,};
            if (!this.loadingData) {
                this.loadingData = true;
                this.updateList(query).then(res => {
                    this.loadingData = false;
                    this.listData = JSON.parse(JSON.stringify(this.list));
                }).catch(err => {
                    this.loadingData = false
                });
            }
          },

          handleSelectionChange(val) {
            this.form.deals = val.map(el => el.id);
          },

          deleteAll() {
            if(this.form.deals && this.form.deals.length > 0){
                this.drawerDeleteDeal = true;
            }else{
                this.$notify({
                    title: this.$t('message.warning'),
                    type: "warning",
                    offset: 130,
                    message: this.$t('message.there_is_no_deals_selected')
                });
            }
          },
          confirmDeleting(){
            this.loadingData = true;
            this.batchDeleteDeal(this.form)
                .then(res => {
                    this.drawerDeleteDeal = false;
                    this.loadingData = false;
                    this.debouncedFetchData();
                    this.$alert(res);
                })
                .catch(err => {
                    this.drawerDeleteDeal = false;
                    this.loadingData = false;
                });
          },

          rowClass(deal){
              let color = '';
              if(deal.paid_money > 0){
                  color = 'mm__row__yellow';
                  if(_.round(deal.paid_money, 2) == this.calculateDealTotal(deal)){
                      color = 'mm__row__green';
                  }else if (_.round(deal.paid_money, 2) > this.calculateDealTotal(deal)){
                      color = 'mm__row__danger';
                  }
              }
              return color;
          },
  
          calculateDealTotal(deal){
              let total = deal.product_total + deal.total_services_price;
              if(deal.cargo_type != 'parcel' && deal.delivery_price && deal.shipping_service){
                  total += (parseFloat(deal.delivery_price) * parseFloat(deal.all_weight))
              }   
              return _.round(total, 2);
          },
          edit(model, edit=false){
              this.selectedDeal = model;
              this.dealCreate = false;
              this.drawerDealCreate = true;
              this.dealEdit = edit;
          },
  
          addModalCreate(dealCreate, cargo_type = '') {
              this.cargo_type = cargo_type;
              this.selectedDeal = null;
              this.dealCreate = dealCreate;
              this.drawerDealCreate = true;
          },
  
          closeAddModel() {
              this.drawerDealCreate = false;
              if(this.reloadList === true){
                  this.debouncedGetList();
              }
          },
          showItems(model) {
              this.dialogItemsVisible = true;
              this.loadingProducts = true;
              let placement = this.filterForm.placement ? this.filterForm.placement : '';
              this.updateDealProducts({id: model.id, placement: placement, show: false})
                  .then((res) => {
                      this.loadingProducts = false;
                  })
                  .catch((err) => {
                      this.loadingProducts = false;
                  });
          },
  
          getSummaries(param) {
              const { columns, data } = param;
              const sums = [];
              if(data && data.length > 0){
  
                  columns.forEach((column, index) => {
                      if (index === 1) {
                          sums[index] = this.$t('message.total');
                          return;
                      }
                      if (column.label === this.$t('message.weight_in_kg')) {
                          const mapped_arr = data.map(item => item.weight);
                          let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                          sums[index] = formatNumber(total, 1) + ' ' + this.$t('message.kg');
                          return;
                      }
                      if (column.label === this.$t('message.weight_in_pounds')) {
                          const mapped_arr = data.map(item => item.weight_in_pounds);
                          let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                          sums[index] = formatNumber(total, 1) + ' ' + 'lbs';
                          return;
                      }
                      if (column.label === this.$t('message.quantity')) {
                          const mapped_arr = data.map(item => item.quantity);
                          let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                          sums[index] = formatNumber(total, 1);
                          return;
                      }
                      if (column.label === this.$t('message.incoming_quantity')) {
                          const mapped_arr = data.map(item => item.incoming_quantity);
                          let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                          sums[index] = formatNumber(total, 1);
                          return;
                      }
  
                      if (column.label === this.$t('message.total')) {
                          const mapped_arr = data.map(item => item.price * item.currency_rate * item.weight);
                          let total = mapped_arr.reduce((prev, next) => parseFloat(prev) + (parseFloat(next) || 0));
                          sums[index] = formatNumber(total, 1) + process.env.VUE_APP_CURRENCY;
                          return;
                      }
                      
                  });
              }
              return sums;
          },
      },
      
  };
  </script>
  <style>
      .space__between {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
      }
      .space__between span {
          cursor: pointer;
          font-size: 18px;
      }
      .mm__row__green {
          background-color: rgb(83, 180, 83);
      }
  
      .mm__row__yellow {
          background-color: rgb(236, 179, 73);
      }
  
      .mm__row__danger {
          background-color: rgb(255, 75, 52);
      }
  
      .filter_filial{
          display: flex;
          flex-direction: row;
          justify-content: space-around;
      }
  
  </style>
  